import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import moment from "moment";
import { Button } from '@mui/material';

const csvPreview = ({ open, handleclose, dataarray, header, handleDownload }) => {
    return (
        <Dialog open={open} onClose={handleclose} maxWidth="sm" fullWidth>
            <div className='pe-3 mt-2' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <DialogTitle>Deck Log Preview</DialogTitle>
                <Button onClick={handleDownload} variant='contained'>Download csv</Button>         
            </div>
            <DialogContent style={{paddingTop: '0'}}>
                <List>
                    <ListItem>
                        <ListItemText primary = {`ORIGIN: ${header[0].name} ${header[0].city? (', ' + header[0].city) : ''}`}></ListItemText>
                    </ListItem>
                    {(header[1]).map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText primary = {`DESTINATION ${index+1}: ${(item.destination).name} ${item.destination.city ? (', ' + item.destination.city) : ""}`}></ListItemText>
                        </ListItem>
                    ))}
                    {dataarray.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={moment(item.datetime).format('YYYY/MM/DD  HH:mmZ')}
                                secondary={
                                    <>
                                        {item.lat? "Latitude: " + item.lat : ""}<br/>
                                        {item.long? "Longitude: " + item.long : ""}<br/>
                                        {item.mile? "Mile: " + item.mile : ""}<br/>
                                        {item.waterway? "Waterway: " + item.waterway : ""}<br/>
                                        {item.description? "Description: " + item.description : ""}
                                    </>
                                }
                            />                            
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default csvPreview
