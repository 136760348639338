import { withApollo } from '@apollo/client/react/hoc';
import { Button, Checkbox, FormControl, InputLabel, ListItemText, ListSubheader, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { useSelector } from 'react-redux';
import LoadingGif from "../../images/boat.gif";
import { fetchCompanyTrips } from '../../services';
import { addEditDeleteTrip } from '../../utils/helper';
import { errorToaster } from '../../utils/messageToast';
import "./SchedulePlanning.scss";
import TripModal from './TripModal';
import OperatorScheduler from './schedulers/OperatorScheduler';
import TripScheduler from "./schedulers/TripScheduler";
import VesselScheduler from './schedulers/VesselScheduler';
import TemplateModal from './templates/TemplateModal';
import _ from 'lodash';

const DATE_MARGIN = moment().subtract(1, 'month')
const DEFAULT_FILTER = 'recent'
const DEFAULT_TAB = 'trip'
const VESSEL_TAB = "vessel"
const OPERATOR_TAB = "operator"
const DARK_DISPLAY_MODE = 'dark'

const INITIAL_NUM_STATUS = {
  active: 0,
  paused: 0,
  planned: 0,
  completed: 0
}

function SchedulePlanning(props) {
  const vesselInfo = useSelector(state => state.user?.company?.vessels)
  const superAdmin = useSelector(state => state.superAdmin)
  const colorBlind = useSelector(state => state.colorBlind)
  const displayMode = useSelector(state => state.displayMode)
 
  const [tripStatus, setTripStatus] = useState(INITIAL_NUM_STATUS)
  const [tripsData, setTripsData] = useState([])
  const [filterDate, setFilterDate] = useState(DATE_MARGIN)
  const [filterData, setFilterData] = useState([])
  const [filterType, setFilterType] = useState(DEFAULT_FILTER)
  const [showTripModal, setShowTripModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tabVal, setTabVal] = useState(DEFAULT_TAB)
  const [vesselConflict, setVesselConflict] = useState(false)
  const [operatorConflict, setOperatorConflict] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [checkedCompanies, setCheckedCompanies] = useState([])
  const [showTemplates, setShowTemplates] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [checked, setChecked] = useState('')

  useEffect(() => {
    document.title = 'RippleGo Dispatcher | Schedule Planning'

    const getTripsWithDate = async (minDate) => {
      setTripStatus(INITIAL_NUM_STATUS)
      
      setLoading(true)
      try {
        const companyTrips = await fetchCompanyTrips(props, { 
          minDate,
          company: "all"
        })

        if(!companyTrips || companyTrips.getCompanyTrips?.length === 0) {
          setTripsData([])
          setLoading(false)
          return
        }
    
        let latestTripDate = new Date()
        let allTripList = new Map()
        for(let trip of companyTrips.getCompanyTrips) {
          let type = 'active'
          if(trip.statuses.length === 0) { type = 'planned'}
          else if(trip.isActive) {
            if(trip.statuses.at(-1).status === 'PAUSE') { type = 'paused'}
          }
          else if(trip.statuses.at(-1).status === 'END') { type = 'completed'}
          
          setTripStatus(prevStatus => ({
            ...prevStatus,
            [type]: prevStatus[type] + 1
          }))
  
          if(new Date(trip.departureDate).getTime() > latestTripDate.getTime()) {
            latestTripDate = new Date(trip.departureDate)
          }

          let companyName = trip.user.company.name
          if(allTripList.has(companyName)) {
            allTripList.set(companyName, allTripList.get(companyName) + 1)
          } 
          else {
            allTripList.set(companyName, 1)
          }
        }

        let companyNames = Array.from(allTripList.entries()).map(([key, _]) => {
          return key
        })
        setCheckedCompanies(companyNames)
        setCompanyList(allTripList)
  
        let sortedTrips = []
        // Sort Destinations
        for(let trip of companyTrips.getCompanyTrips) {
          let sortedDestinations = [...trip.destinations]
          sortedDestinations = sortedDestinations.sort((a, b) => a.legNumber - b.legNumber)

          sortedTrips.push({
            ...trip,
            destinations: sortedDestinations
          })
        }

        // Sort by Departure Date
        sortedTrips.sort((a,b) => new Date(b.departureDate).getTime() - new Date(a.departureDate).getTime())

        setTripsData(sortedTrips)
        setFilterData(sortedTrips)
      } catch (error) {
        errorToaster("Unable to fetch company's trip at the moment. Please try again later.")
      }
      setLoading(false)
    }

    getTripsWithDate(filterDate)
  }, [vesselInfo, filterDate, props])

  const sortAndFilterOnchange = (e, tabValue) => {
    
    let sortedTrips = [...tripsData]
    let type = tabValue;

    setChecked(type)

    if(e){
      type = e.target.value
    }

    sortedTrips = sortedTrips.filter(trip => checkedCompanies.includes(trip.user.company.name))

    setFilterType(type)
    
    switch(type) {
      case "recent":
        sortedTrips = sortedTrips.sort((a,b) => new Date(b.departureDate).getTime() - new Date(a.departureDate).getTime())
        break;
      case "old":
        sortedTrips = sortedTrips.sort((a,b) => new Date(a.departureDate).getTime() - new Date(b.departureDate).getTime())
        break;
      case "name":
        if(tabValue === 'trip') {
          sortedTrips = sortedTrips.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
        else if(tabValue === 'vessel') {
          sortedTrips = sortedTrips.sort((a,b) => (a.vessel.name > b.vessel.name) ? 1 : ((b.vessel.name > a.vessel.name) ? -1 : 0))
        }
        else if(tabValue === 'operator') {
          sortedTrips = sortedTrips.sort((a,b) => (a.user.firstName > b.user.firstName) ? 1 : ((b.user.firstName > a.user.firstName) ? -1 : 0))
        }
        break;
      case "active":
        sortedTrips = sortedTrips.filter(trip => trip.isActive)
        break;
      case "paused":
        sortedTrips = sortedTrips.filter(trip => trip.statuses.length > 0 && trip.statuses.at(-1).status === "PAUSE")
        break;
      case "completed":
        sortedTrips = sortedTrips.filter(trip => trip.statuses.length > 0 && trip.statuses.at(-1).status === "END")
        break;
      case "planned":
        sortedTrips = sortedTrips.filter(trip => trip.statuses.length === 0)
        break;
      default: 
        break;
    }

    setFilterData(sortedTrips)
  }

  const handleTripUpdate = (trip, type) => {
    let trips = addEditDeleteTrip(trip, tripsData, type)

    setTripsData(trips)
    setFilterData(trips)
    setShowTripModal(false)
  }

  const tabOnChange = (_, val) => {
    setTabVal(val)
    sortAndFilterOnchange({target: {value: filterType}}, val)
  }

  const checkOverlapping = (tab, hasOverlapping) => {
    switch (tab) {
      case VESSEL_TAB:
        setVesselConflict(hasOverlapping)
        break;
      case OPERATOR_TAB:
        setOperatorConflict(hasOverlapping)
        break;
      default:
        break;
    }
  }

  const handleCompanyFilter = e => {
    const {
      target: { value },
    } = e;
    setCheckedCompanies(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    let filterTrips = [...tripsData]
    filterTrips = filterTrips.filter(trip => value.includes(trip.user.company.name))
    setFilterData(filterTrips)
  }

  const templateOnSelect = (data, action) => {
    let templateData = _.clone(data)
    templateData.tripName = data.templateName
    setSelectedTemplate(templateData)
    setShowTripModal(true)
    setShowTemplates(false)
  }

  const listTemplates = () => {
    setShowTripModal(false)
    setShowTemplates(true)
  }

  const handleTemplatesOnClose = () => {
    setShowTripModal(true)
    setShowTemplates(false)
    setSelectedTemplate(null)
  }

  const handleAddTripOnClick = () => {
    setSelectedTemplate(null)
    setShowTripModal(true)
  }
  
  const handleToggle = (type) => {
    if(type === checked){
      sortAndFilterOnchange(null,"recent");
      return
    }
    setChecked(type)
    sortAndFilterOnchange(null,type);
  }

  return (
    <>
      <h2>
        Schedule Planning
      </h2>

      <div 
        style={{
          color: displayMode === DARK_DISPLAY_MODE? "white" : 'inherit',
          background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
        }}
        className='trip-table-legends mt-4 mb-4 shadow'
      >
          <div className='trip-table-legend center'>
            <div className={'trip-table-legend-item ' + (colorBlind? "color-blind-legend" : "")}>{tripStatus.active}</div>
            Active Trips
            <Checkbox
              id="active-toggle"
              name="active-toggle"
              checked={checked === 'active'}
              sx={{
                color: colorBlind? "#19168c":"#008F85",
                '&.Mui-checked': {
                  color: colorBlind? "#19168c":"#008F85",
                },
              }}
              onChange={()=>handleToggle('active')}
            />
          </div>

        <div className='trip-table-legend center'>
          <div className='trip-table-legend-item paused'>{tripStatus.paused}</div>
          Paused Trips
          <Checkbox
            id="paused-toggle"
            name="paused-toggle"
            checked={checked === 'paused'}
            sx={{
              color: "#CD2C2C",
              '&.Mui-checked': {
                color: "#CD2C2C",
              },
            }}
            onChange={()=>handleToggle('paused')}
          />
        </div>

        <div className='trip-table-legend center'>
          <div 
            className={'trip-table-legend-item planned ' + (colorBlind? "color-blind-planned" : "")}
            style={{color: displayMode === DARK_DISPLAY_MODE? "white" : 'black'}}
          >
            {tripStatus.planned}
          </div>
          Planned Trips
          <Checkbox
            id="planned-toggle"
            name="planned-planned"
            checked={checked === 'planned'}
            sx={{
              color: colorBlind? "#19168c" : "#808080",
              '&.Mui-checked': {
                color: colorBlind? "#19168c" : "#808080",
              },
            }}
            onChange={()=>handleToggle('planned')}
          />
        </div>

        <div className='trip-table-legend center'>
          <div className='trip-table-legend-item completed'>{tripStatus.completed}</div>
          Completed Trips
          <Checkbox
            id="completed-toggle"
            name="completed-toggle"
            checked={checked === 'completed'}
            sx={{
              color: "#808080",
              '&.Mui-checked': {
                color: "#808080",
              },
            }}
            onChange={()=>handleToggle('completed')}
          />
        </div>
      </div>

      {loading ? 
        <img className='loading' src={LoadingGif} alt="Loading..." />
        :
        <div 
          style={{
            color: displayMode === DARK_DISPLAY_MODE? "white" : 'inherit',
            background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
          }}
          className='card shadow'
        >
          <div className='card-body'>
            <div className='center-y mb-1'>
              <h5>Scheduler</h5>
              <Button
                variant='contained'
                onClick={handleAddTripOnClick}
              >
                <span className="px-2"><b>+ Add Trip</b></span>
              </Button>
            </div>

            <Tabs value={tabVal} onChange={tabOnChange}>
              <Tab label="Trip" value="trip"/>
              <Tab label="Vessel" value="vessel" icon={vesselConflict ? <AlertTriangle stroke="black" strokeWidth={2} fill="#FFC000" size={20}/> : ""} iconPosition="start"/>
              <Tab label="Operator" value="operator" icon={operatorConflict ? <AlertTriangle stroke="black" strokeWidth={2} fill="#FFC000" size={20}/> : ""} iconPosition="start"/>
            </Tabs>

            <hr style={{opacity: 0.15, marginTop: 0}}/>

            <div className='mt-5 mb-4 center justify-content-start'>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Filter by Departure Date"
                    value={filterDate}
                    onChange={(newValue) => {
                      setFilterDate(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
              </LocalizationProvider>

              <div
                className='bg-dark-gray mx-4'
                style={{
                  width: 1,
                  height: 50,
                  opacity: 0.5
                }}
              ></div>

              <FormControl style={{width: 300}}>
                <InputLabel id="filter-dropdown">Sort / Filter by:</InputLabel>
                <Select
                  className='text-capitalize'
                  labelId='filter-dropdown'
                  label="Sort / Filter by:"
                  value={filterType}
                  autoWidth={true}
                  onChange={e => sortAndFilterOnchange(e, e.target.value)}
                >
                  <ListSubheader>Sort By</ListSubheader>
                  <MenuItem value="recent">Departure Date From Recent to Old</MenuItem>
                  <MenuItem value="old">Departure Date From Old to Recent</MenuItem>
                  <MenuItem value="name" className='text-capitalize'>{tabVal} Name</MenuItem>
                  <ListSubheader>Filter By</ListSubheader>
                  <MenuItem value="active">Active Trips</MenuItem>
                  <MenuItem value="paused">Paused Trips</MenuItem>
                  <MenuItem value="planned">Planned Trips</MenuItem>
                  <MenuItem value="completed">Completed Trips</MenuItem>
                </Select>
              </FormControl>

              {superAdmin &&
                <FormControl className='ms-2' style={{width: 300}}>
                  <InputLabel id="filter-company-dropdown">Filter companies:</InputLabel>
                  <Select
                    className='text-capitalize'
                    labelId='filter-company-dropdown'
                    multiple
                    label="Filter companies"
                    value={checkedCompanies}
                    onChange={handleCompanyFilter}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {Array.from(companyList.entries()).map(([key, val]) => (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={checkedCompanies.includes(key)}/>
                        <ListItemText primary={`${key} (${val})`}/>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            </div>

            {filterData.length > 0 ?
              <>
                <div className={tabVal === DEFAULT_TAB ? '' : 'd-none'}>
                  <TripScheduler tripsData={filterData} handleTripUpdate={handleTripUpdate}/>
                </div>
                <div className={tabVal === VESSEL_TAB ? '' : 'd-none'}>
                  <VesselScheduler tripsData={filterData} handleTripUpdate={handleTripUpdate} checkOverlapping={checkOverlapping}/>
                </div>
                <div className={tabVal === OPERATOR_TAB ? '' : 'd-none'}>
                  <OperatorScheduler tripsData={filterData} handleTripUpdate={handleTripUpdate} checkOverlapping={checkOverlapping}/>
                </div>

                {((tabVal === VESSEL_TAB && vesselConflict) || (tabVal === OPERATOR_TAB && operatorConflict)) && <small className='ms-4 my-4' style={{opacity: 0.8, fontSize: 14}}><i><AlertTriangle stroke="black" strokeWidth={2} fill="#FFC000" size={20}/> - The trips are overlapping with the same {tabVal}, indicating a scheduling conflict that needs to be resolved.</i></small>}
              </>
              :
              <div className='mt-5 mb-4 center'>
                <h6>
                  There's no {filterType === 'active' || filterType === 'paused' ? filterType : ""} trip after {filterDate.format("MM/DD/YYYY")}
                </h6>
              </div>
            }
          </div>
        </div>
      }

      {/* For Add Trip */}
      {showTripModal &&
        <TripModal 
          show={showTripModal}
          onHide={() => setShowTripModal(false)} 
          disabled={false}
          handleTripUpdate={handleTripUpdate}
          listTemplates={listTemplates}
          tripInfo={selectedTemplate}
          isTemplateData={selectedTemplate ? true : false}
        />
      }

      {/* List Templates */}
      {showTemplates &&
        <TemplateModal
          show={showTemplates}
          onHide={handleTemplatesOnClose}
          templateOnSelect={templateOnSelect}
        />
      }
    </>
  )
}

export default withApollo(SchedulePlanning)