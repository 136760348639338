import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCircleUser} from "@fortawesome/free-solid-svg-icons"
import Dropdown from 'react-bootstrap/Dropdown';
import { logoutSession } from '../../store/action';
import ReportAndFeedbackForm from '../feedbackForm/ReportAndFeedbackForm.js'
import './Nav.scss'

const DARK_DISPLAY_MODE = 'dark'
const FEEDBACK = 'feedback'
const REPORT = 'report'

export default function NavbarTop() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const name = useSelector(state => state.user?.firstName)
  const displayMode = useSelector(state => state.displayMode)
  const [formType, setFormType] = useState("")
  const [openForm, setOpenForm] = useState(false)

  const handleLogout = () => {
    dispatch(logoutSession(false))
    navigate('/login')
  }

  const handleOpenForm = (type) => { 
    setFormType(type)
    setOpenForm(true) 
  }

  const handleCloseForm = () => { 
    setOpenForm(false) 
  }  

  return (
    <>
      <nav className={'nav shadow py-2 px-4 center justify-content-end '+ (displayMode === DARK_DISPLAY_MODE ? 'dark-mode' : "")} style={{height: 50}}>
        <Dropdown>
            <Dropdown.Toggle 
              className="btn btn-white dropdown-toggle center" 
              type="button" 
              id="user_dropdown" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
              style={{
                color: displayMode === DARK_DISPLAY_MODE? "white" : 'inherit',
                background: displayMode === DARK_DISPLAY_MODE? "#2F2F2F" : 'white'
              }}
            >
                <FontAwesomeIcon 
                  icon={faCircleUser}
                  style={{
                    color: displayMode === DARK_DISPLAY_MODE? "white" : '#004251',
                  }} 
                  size='lg'
                /> 
                <span className='mx-2'>{name ? name : "Welcome"}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{padding: 0}}>
                {/* <Dropdown.Item as={Link} to="/message-center">Messages</Dropdown.Item> */}
                {/* <Dropdown.Divider /> */}
                {/* <Dropdown.Item>Change Password</Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/settings" style={{padding: '6px 0', textAlign: 'center'}}>Settings</Dropdown.Item>
                <hr style={{margin: 0}}/>
                <Dropdown.Item onClick={() => handleOpenForm(FEEDBACK)} style={{padding: '6px 0', textAlign: 'center'}}>Feedback</Dropdown.Item>
                <hr style={{margin: 0}}/>
                <Dropdown.Item onClick={() => handleOpenForm(REPORT)} style={{padding: '6px 0', textAlign: 'center'}}>Report Issue</Dropdown.Item>
                <hr style={{margin: 0}}/>
                <Dropdown.Item onClick={handleLogout} style={{padding: '6px 0', textAlign: 'center'}}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
      </nav>

      <ReportAndFeedbackForm 
        openForm={openForm}
        handleCloseForm={handleCloseForm}
        formType={formType}
        displayMode={displayMode}
      />    
    </>

  )
}